import axios from "axios";

var getBASE_URL = function ()
{
  if (process.env.NODE_ENV === 'production') {
    if (process.env.REACT_APP_API_MODE != "STAGE") {
      return "https://app2.altrack.co.za/webapi";
    } else {
      return "https://app2.altrack.co.za/webapi/tdebug";
    }
  } else {    
    if (process.env.REACT_APP_API_MODE == "STAGE") {
      return "https://app2.altrack.co.za/webapi/tdebug";
    } else {
      return "https://localhost:44319";
    }
  }
};

// "https://localhost:44319"; //TODO: check live
//const BASE_URL = "https://localhost:44319";

export default axios.create({
  baseURL: getBASE_URL(),
});

export const axiosPrivate = axios.create({
  baseURL: getBASE_URL(),
  headers: { "Content-Type": "application/json" },
  withCredentials: true
});

